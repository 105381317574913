@import '~antd/dist/antd.less';

body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn {
  //height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    color: rgba(184, 184, 184, 1);
    background: #f5f5f5;
    border: none;
    text-shadow: none;
    box-shadow: none;
    font-weight: bold;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .ant-btn {
    height: 28px !important;
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    background: #09224d;
  }
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background: #e000e4;
  }
}

.sohCool-affix {
  .ant-affix {
    background-color: #1F1935;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  }
}

.ant-switch-checked {
  background-image: linear-gradient(94.8deg, #E000E4 4.74%, #14DEF5 100%);
}

.Hedging_TokenPool_ToolTip {
  .ant-tooltip-inner {
    color: rgba(0, 0, 0, .85);
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
  }
  .ant-tooltip-arrow-content {
    background-color: #ffffff;
  }
}
.ant-popover-placement-bottomLeft {
  padding-top: 0 !important;
}

//lang
.ant-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      min-width: 108px;
      background: #101322;
      border-radius: 4px;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    }
  }
  &.lang-popover, &.option-popover {
    .ant-popover-content {
      .ant-popover-inner-content {
        padding: 6px 16px;
      }
    }
  }
}
.ant-menu {
  &.lang-box {
    border-right: none;
    background-color: transparent;
    li.ant-menu-item {
      font-weight: 600;
      font-size: 14px;
      color: #d8Ced8;
      margin: 0;
      padding: 0;
      text-align: center;
      &:hover {
        color: #19d9f5;
      }
      &.ant-menu-item-selected {
        background-color: transparent;
      }
    }
  }
}

// second menu
.ant-menu-vertical {
  background: #101322 !important;
  .ant-menu-item a {
    color: #d8Ced8;
    &:hover {
      color: #fff5ff;
    }
  }
  .ant-menu-item-selected {
    background: transparent !important;
    color: #fff5ff !important;
  }
}

//notification
.ant-notification-notice {
  padding: 20px !important;
  background: #101322 !important;
  color: #fff5ff !important;
}

.ant-notification-notice-close,
.ant-notification-notice-message {
  color: #fff5ff !important;
  margin-left: 38px !important;
}

.ant-notification-notice-message {
  font-weight: 600 !important;
}

.ant-notification-notice-description {
  margin-left: 38px !important;
}

.ant-notification-topRight {
  top: 72px !important;
}

//pagination
.ant-pagination-item {
  min-width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
  border-radius: 4px !important;
}

.ant-pagination-item a,
.ant-pagination-item-link {
  color: #fff !important;
}

.ant-pagination-item-active a,
.ant-pagination-item a:hover {
  color: #19d9f5 !important;
}

.ant-pagination-item-active {
  border-color: #19d9f5 !important;
  background: transparent !important;
}

.ant-pagination-disabled {
  opacity: 0.5;
}

.ant-switch-handle {
  top: 1px!important;
  left: 1px!important;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 1px) !important;
}

.ant-switch-checked:focus {
  box-shadow: none!important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #fff5ff;
  background-color: #1f183c!important;
  border: 1px solid #8e8e8e!important;
}

.ant-select-arrow .anticon > svg {
  color: #8e8e8e;
}

.ant-select-dropdown {
  background-color: #1f183c !important;//2a264c
  color: #fff5ff!important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #2a264c!important;
  color: #fff5ff!important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #2a264c!important;
}

.ant-select-item {
  color: #fff5ff!important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #ffffff!important;
}

@primary-color: #E001E5;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;